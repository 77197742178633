import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        
        <p>
          Lalpur Garden Academy website is under maintenance.
        </p>
        <code>Please, check back later.</code>
      </header>
    </div>
  );
}

export default App;
